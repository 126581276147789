(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("fabric")["fabric"], require("tui-code-snippet"), require("tui-color-picker"));
	else if(typeof define === 'function' && define.amd)
		define(["fabric", "tui-code-snippet", "tui-color-picker"], factory);
	else if(typeof exports === 'object')
		exports["ImageEditor"] = factory(require("fabric")["fabric"], require("tui-code-snippet"), require("tui-color-picker"));
	else
		root["tui"] = root["tui"] || {}, root["tui"]["ImageEditor"] = factory(root["fabric"], root["tui"]["util"], root["tui"]["colorPicker"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_fabric__, __WEBPACK_EXTERNAL_MODULE_tui_code_snippet__, __WEBPACK_EXTERNAL_MODULE_tui_color_picker__) {
return 